jQuery(document).ready(function($){
    $('.navbar-inverse').css("background-color", "transparent");
    $(window).scroll(function(){
    if ($(this).scrollTop() > 300) {
        $('.navbar-inverse').css("background-color", "rgba(16, 52, 82, 1)").css("transition","0.8s ease-in-out ");
        $('.navbar-right > li > a').css("border-color", "rgba(195, 156, 74, 1)");
    } else if($(this).scrollTop() > 200){
        $('.navbar-inverse').css("background-color", "rgba(16, 52, 82, 0.75)").css("transition","0.8s ease-in-out ");
        $('.navbar-right > li > a').css("border-color", "rgba(195, 156, 74, 0.75)");
        $('.navbar-right > li > a').css("border-color", "rgba(255, 255, 255, 1)");
    } else if($(this).scrollTop() > 100){
        $('.navbar-inverse').css("background-color", "rgba(16, 52, 82, 0.5)").css("transition","0.8s ease-in-out ");
        $('.navbar-right > li > a').css("border-color", "rgba(195, 156, 74, 0.5)");
        $('.navbar-right > li > a').css("border-color", "rgba(255, 255, 255, 1)");
    }
    else{
        $('.navbar-inverse').css("background-color", "transparent").css("transition","0.8s ease-in-out ");
        $('.navbar-right > li > a').css("border-color", "rgba(255, 255, 255, 1)");
    }
    });
});