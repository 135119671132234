var interval = 5000;
$(document).ready(function() {
$('.step2-btn').mouseenter(function() {
  window.clearTimeout(interval);
  $('.widget-container > img').css('opacity', 0);
    $('.widget-container-text > h2').css('opacity', 0);
    $('.step2').css('opacity', 1);
    $('.step1').css('opacity', 0);
});
$('.step2-btn').mouseleave(function() {
    window.setTimeout(function(){
        $('.step2').css('opacity', 0);
        $('.step1').css('opacity', 1);
      }, interval);
});
$('.step3-btn').mouseenter(function() {
  window.clearTimeout(interval);
  $('.widget-container > img').css('opacity', 0);
    $('.widget-container-text > h2').css('opacity', 0);
    $('.step3').css('opacity', 1);
    $('.step1').css('opacity', 0);
});
$('.step3-btn').mouseleave(function() {
    window.setTimeout(function(){
        $('.step3').css('opacity', 0);
        $('.step1').css('opacity', 1);
      }, interval);
});
$('.step4-btn').mouseenter(function() {
    window.clearTimeout(interval);
    $('.widget-container > img').css('opacity', 0);
    $('.widget-container-text > h2').css('opacity', 0);
    $('.step4').css('opacity', 1);
    $('.step1').css('opacity', 0);
});
$('.step4-btn').mouseleave(function() {
    window.setTimeout(function(){
        $('.step4').css('opacity', 0);
        $('.step1').css('opacity', 1);
      }, interval);
});
$('.step5-btn').mouseenter(function() {
  window.clearTimeout(interval);
  $('.widget-container > img').css('opacity', 0);
    $('.widget-container-text > h2').css('opacity', 0);
    $('.step5').css('opacity', 1);
    $('.step1').css('opacity', 0);
});
$('.step5-btn').mouseleave(function() {
    window.setTimeout(function(){
        $('.step5').css('opacity', 0);
        $('.step1').css('opacity', 1);
      }, interval);
});
});
