$(function () {
    $("#recruiterForm").validate({
        submitHandler: function (form) {
            document.getElementById("recruiterForm").submit();
        } 
    });
});

$(function () {
    $("#andelaForm").validate({
        submitHandler: function (form) {
            document.getElementById("andelaForm").submit();
        } 
    });
});

$(function () {
    $("#techForm").validate({
        submitHandler: function (form) {
            document.getElementById("techForm").submit();
        } 
    });
});